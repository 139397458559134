import { TABLA } from '@/utils/consts'

export default {
  async selectTanomaliaChecklistOt (Vue, idtanomaliaChecklistOt) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.tanomalia_checklist_ot)
      .innerJoin(
        tables.pregunta_checklist_ot,
        tables.tanomalia_checklist_ot.idpregunta_checklist_ot.eq(tables.pregunta_checklist_ot.idpregunta_checklist_ot)
      )
      .innerJoin(
        tables.grupo_checklist_ot,
        tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
      )
      .innerJoin(
        tables.checklist_ot,
        tables.grupo_checklist_ot.idchecklist_ot.eq(tables.checklist_ot.idchecklist_ot)
      )
      .innerJoin(
        tables.grado_anomalia,
        tables.tanomalia_checklist_ot.idgrado_anomalia.eq(tables.grado_anomalia.idgrado_anomalia)
      )
      .where(tables.tanomalia_checklist_ot.idtanomalia_checklist_ot.eq(idtanomaliaChecklistOt))
      .exec())[0]
  },
  async selectFicherosCount (Vue, idtanomaliaChecklistOt) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select(Vue.$offline.db.fn.count(tables.fichero.idfichero).as('count'))
      .from(tables.fichero)
      .where(
        Vue.$offline.db.op.and(
          tables.fichero.idtabla.eq(TABLA.tanomalia_checklist_ot.idtabla),
          tables.fichero.id.eq(idtanomaliaChecklistOt),
        )
      )
      .exec())[0]
  },
  async selectAccionesCorrectorasCount (Vue, idtanomaliaChecklistOt) {
    const tables = Vue.$offline.db.tables
    const accionCorrectoraOt = Vue.$offline.accionCorrectoraOt
    return (await Vue.$offline.db
      .select(Vue.$offline.db.fn.count(tables.accion_correctora_ot.idaccion_correctora_ot).as('count'))
      .from(tables.accion_correctora_ot)
      .innerJoin(accionCorrectoraOt.dummyTable, accionCorrectoraOt.pk.eq(accionCorrectoraOt.dummyPk))
      .where(tables.accion_correctora_ot.idtanomalia_checklist_ot.eq(idtanomaliaChecklistOt))
      .exec())[0]
  },
  selectFicheroAnomalia (Vue, idtanomaliaChecklistOt) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.fichero)
      .innerJoin(
        tables.tfichero,
        tables.fichero.idtfichero.eq(tables.tfichero.idtfichero)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.fichero.id.eq(idtanomaliaChecklistOt),
          tables.fichero.idtabla.eq(TABLA.tanomalia_checklist_ot.idtabla),
        )
      )
      .exec()
  },
}
